import { useEffect } from "react"
import React from "react"

import { Helmet } from "react-helmet"
import { useDispatch, useSelector } from "react-redux"

import { Loader } from "~/components/ui"
import { navigate } from "~/gatsby/navigate"
import useIsClient from "~/hooks/useIsClient"
import useSiteMetadata from "~/hooks/useSiteMetadata"
import { useGetUserRecipeQuery } from "~/state/api/communityRecipes"
import { profileApi } from "~/state/api/profile"
import { useGetRecipesQuery } from "~/state/api/recipesApi"
import { saveDjangoUserTokenInLocalStorage } from "~/state/django-compatibility"
import { authSelector, setUserToken } from "~/state/modules/auth"

const RecipeRedirection = (props: any) => {
  const { siteUrl } = useSiteMetadata()
  let paramToken = null
  const isClient = useIsClient()
  if (isClient) {
    const urlParams = new URLSearchParams(window.location.search)
    paramToken = urlParams.get("token")
  }

  const dispatch = useDispatch()
  if (paramToken?.length) {
    dispatch(setUserToken(paramToken))
    saveDjangoUserTokenInLocalStorage(paramToken)
    profileApi.endpoints.profile.initiate(paramToken)
  }

  const { token, isLoggedIn } = useSelector(authSelector)

  const {
    data: apiRecipes,
    isLoading,
    isSuccess: isRecipesSuccess,
  } = useGetRecipesQuery({
    in_id: [props.id],
    allproperties: "true",
  })

  const {
    data: apiCommunityRecipe,
    isSuccess: apiCommunityRecipeSuccess,
    isError: apiCommunityRecipeError,
    refetch: refetchCommunityRecipe,
  } = useGetUserRecipeQuery(
    {
      uuid: props.id,
    },
    {
      skip: !token,
    }
  )

  useEffect(() => {
    refetchCommunityRecipe()
  }, [token, isLoggedIn])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const editMode = urlParams.get("em")
    const unknownRecipe =
      apiRecipes &&
      apiRecipes?.data[0] === undefined &&
      apiCommunityRecipe === undefined
    if (
      (apiCommunityRecipeSuccess || apiCommunityRecipeError) &&
      isRecipesSuccess &&
      unknownRecipe
    ) {
      navigate(`${siteUrl}`, { replace: true }) //No recipe found -> Navigate to chefclub.tv
    } else if (apiRecipes?.data[0] && apiRecipes?.data[0].absolute_url) {
      navigate(`${apiRecipes?.data[0].absolute_url}`, { replace: true }) //Navigate to recipe url
    } else if (apiCommunityRecipe?.absolute_url) {
      navigate(
        `${apiCommunityRecipe?.absolute_url}${editMode ? "?em=true" : ""}`,
        { replace: true }
      ) //Navigate to community recipe url with edit mode if needed
    }
  }, [
    apiRecipes,
    isLoading,
    apiCommunityRecipeSuccess,
    isRecipesSuccess,
    siteUrl,
    apiCommunityRecipeError,
  ])

  return (
    <div>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Loader />
    </div>
  )
}

export default RecipeRedirection
